(function($, window, document, undefined) {
    'use strict';

    // PROYECTOS
    // init cubeportfolio
    let $projectsGridContainer = $('#grid-container-projects');
    $projectsGridContainer.cubeportfolio({
        filters: '#filters-container',
        loadMore: '#loadMore-container',
        loadMoreAction: 'click',
        layoutMode: 'grid',
        defaultFilter: '*',
        animationType: 'quicksand',
        gapHorizontal: 35,
        gapVertical: 25,
        gridAdjustment: 'responsive',
        mediaQueries: [{
            width: 1100,
            cols: 4
        }, {
            width: 800,
            cols: 3
        }, {
            width: 500,
            cols: 2
        }, {
            width: 320,
            cols: 1
        }],
        caption: 'zoom',
        displayType: 'lazyLoading',
        displayTypeSpeed: 100,

        // lightbox
        lightboxDelegate: '.cbp-lightbox',
        lightboxGallery: true,
        lightboxTitleSrc: 'data-title',
        lightboxCounter: '<div class="cbp-popup-lightbox-counter">{{current}} ' + $projectsGridContainer.data('string-of') + ' {{total}}</div>',

        // singlePage popup
        singlePageDelegate: '.cbp-singlePage',
        singlePageDeeplinking: true,
        singlePageStickyNavigation: true,
        singlePageCounter: '<div class="cbp-popup-singlePage-counter">{{current}} ' + $projectsGridContainer.data('string-of') + ' {{total}}</div>',
        singlePageCallback: function(url, element) {
            // to update singlePage content use the following method: this.updateSinglePage(yourContent)
            let t = this;

            $.ajax({
                url: url,
                type: 'GET',
                dataType: 'html',
                timeout: 5000
            })
                .done(function(result) {
                    t.updateSinglePage(result);
                })
                .fail(function() {
                    t.updateSinglePage("Error! Please refresh the page!");
                });
        },
    });

    // Galería
    // init cubeportfolio
    let $galleryGridContainer = $('#grid-container-gallery');
    $galleryGridContainer.cubeportfolio({
        filters: '#filters-container',
        loadMore: '#loadMore-container',
        loadMoreAction: 'auto',
        layoutMode: 'grid',
        defaultFilter: '*',
        animationType: 'fadeOutTop',
        gapHorizontal: 0,
        gapVertical: 0,
        gridAdjustment: 'responsive',
        mediaQueries: [{
            width: 1600,
            cols: 5
        }, {
            width: 1200,
            cols: 4
        }, {
            width: 800,
            cols: 3
        }, {
            width: 500,
            cols: 2
        }, {
            width: 320,
            cols: 1
        }],
        caption: 'zoom',
        displayType: 'lazyLoading',
        displayTypeSpeed: 100,

        // lightbox
        lightboxDelegate: '.cbp-lightbox',
        lightboxGallery: true,
        lightboxTitleSrc: 'data-title',
        lightboxCounter: '<div class="cbp-popup-lightbox-counter">{{current}} ' + $galleryGridContainer.data('string-of') + ' {{total}}</div>',
    });

})(jQuery, window, document);




// CONTACT MAP

var PageContact2 = function() {

    var _init = function() {

        var $map = $('#gmapbg');
        var style = $map.data('style');
        var longitude = $map.data('longitude');
        var latitude = $map.data('latitude');
        var zoom = $map.data('zoom');

        if (style === 'streetView') {
            var mapbg = GMaps.createPanorama({
                el: '#gmapbg',
                lat: latitude,
                lng: longitude,
                scrollwheel: false,
                mapType: style
            });
        } else {
            var mapbg = new GMaps({
                el: '#gmapbg',
                lat: latitude,
                lng: longitude,
                zoom: zoom,
            })
        }


    }

    return {
        //main function to initiate the module
        init: function() {

            _init();

        }

    };
}();

$(document).ready(function() {
    PageContact2.init();
    $( window ).resize(function() {
        PageContact2.init();
    });
});